<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <b-form enctype="multipart/form-data">
              <div class="row">
                <div class="col-4">
                  <label style="font-weight: 500">Tag Name <span style="color: red">*</span></label>
                  <div>
                    <input type="text" placeholder="Enter Tag Name" v-model="form.name" :class="{ 'is-invalid': submitted && $v.form.name.$invalid }" class="w-100 form-control" />
                    <div v-if="submitted && !$v.form.name.required" class="invalid-feedback mb-2">Name is
                          required.</div>
                  </div>
                </div>
                <b-form-group label-for="color" class="col-4">
                  <label style="font-weight: 500">Tag Color <span style="color: red">*</span></label>
                  <b-form-input type="color" id="color" v-model="form.color" :class="{ 'is-invalid': submitted && $v.form.color.$invalid }" />
                  <div v-if="submitted && !$v.form.color.required" class="invalid-feedback mb-2">Color is
                          required.</div>
                </b-form-group>
                <b-form-group id="input-group-5" label-for="input-5"
                    class="col-4">
                    <label style="font-weight: 500">Tag Icon [Upload Max File Size : 2MB]"</label>
                    <b-form-file id="input-5" accept="image/*" placeholder="Choose a file or drop it here..."
                      @change="readFile($event, 'badge_icon')" ref="badge_icon"></b-form-file>
                    <template v-if="
                      $route.name == 'edit-member-tag-type' && edit.badge_icon_url
                    ">
                      <img :src="edit.badge_icon_url" width="128px" height="128px"
                        style="object-fit: contain; margin-top: 5px" />
                    </template>
                    <template v-if="badge_icon_url">
                      <img :src="badge_icon_url" width="128px" height="128px" ref="badge_icon_url"
                        style="object-fit: contain; margin-top: 5px" />
                    </template>
                </b-form-group>
                <div class="col-12">
                  <div class="d-flex justify-content-start align-items-center mb-3">
                    <label class="mb-0" style="font-weight: 500">Send Email HTML Content</label>
                    <span class="badge badge-primary ml-3 p-1" style="cursor: pointer" @click="formatCode" v-if="form.email_html">Format Code</span>
                  </div>
                  <ace-editor v-model="form.email_html" @init="editorInit" lang="html" theme="chrome" width="100%" height="1000" class="mb-3"></ace-editor>
                </div>
                <div class="col-12 mb-3" v-if="form.email_html">
                  <b-button class="btn btn-info btn-sm" @click="showPreview">
                    <span v-if="previewEmail">Close Preview</span>
                    <span v-else>Preview HTML Content</span>
                  </b-button>
                  <div v-if="previewEmail && form.email_html">
                    <label class="my-3 d-block text-center">Preview of HTML Content</label>
                    <div v-html="form.email_html" class="d-flex align-items-center"></div>
                  </div>
                </div>
                <b-form-group class="col-12">
                  <b-form-checkbox v-model="form.is_active"><label style="font-weight: 500">Display Tag</label></b-form-checkbox>
                </b-form-group>
              </div>
              <b-button type="submit" variant="primary" class="mr-2" @click.prevent="submitData"><span
                    v-if="this.$route.name == 'add-member-tag-type'">Save Data</span>
                <span v-else>Update Data</span>
              </b-button>
            </b-form>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>
  
<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import MixinRequest from "../../../mixins/request";
import memberTag from "../../../mixins/ModuleJs/member-tag";
import { required } from "vuelidate/lib/validators";

export default {
  data() {
    return {
      submitted: false,
      title: "Member Tag Type",
      items: [
        {
          text: "Back",
          href: "/member-tag-type",
        },
        {
          text: "Data",
        },
      ],
    };
  },
  validations: {
    form: {
      name: { required },
      color: { required },
    }
  },
  mixins: [MixinRequest, memberTag],
  components: {
    Layout,
    PageHeader,
  },
}
</script>
  